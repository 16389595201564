<template>
  <div class="uk-form-stacked">
    <h4 class="uk-heading-line">
      Facility Info
    </h4>
    <div
      v-if="loadingDetail"
      class="uk-flex uk-flex-center uk-flex-middle"
    >
      <div uk-spinner />
    </div>
    <div
      v-else
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              VCF Code
            </label>
            <div class="uk-form-controls">
              <input
                name="vcf_code"
                :value="detailPlantation.vcf_code || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Export Hub
            </label>
            <div class="uk-form-controls">
              <input
                name="export_hub"
                :value="detailPlantation.export_hub?.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Pulping Facility
            </label>
            <div class="uk-form-controls">
              <input
                name="pulping_facility"
                :value="detailPlantation.pulping_facility?.facility_name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Species Type
            </label>
            <div class="uk-form-controls">
              <input
                name="species_type"
                :value="detailPlantation.species?.name|| '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <div class="uk-form-label uk-margin-auto-vertical">
              Size
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                name="size"
                :value="detailPlantation?.size !=0 ? formatCurrency(detailPlantation.size) : '-' || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
              <label class="label-custom">Hectare</label>
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Latitude
            </label>
            <div class="uk-form-controls">
              <input
                name="latitude"
                :value="detailPlantation.latitude || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Potensi Panen
            </label>
            <div class="uk-form-controls">
              <input
                name="harvest_potential"
                :value="detailPlantation.harvest_potential || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Facility Name
            </label>
            <div class="uk-form-controls">
              <input
                name="facility_name"
                :value="detailPlantation.facility_name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Hulling Facility
            </label>
            <div class="uk-form-controls">
              <input
                name="hulling_facility"
                :value="detailPlantation.hulling_facility?.facility_name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Varieties Type
            </label>
            <div class="uk-form-controls">
              <input
                name="varieties_type"
                :value="detailPlantation.varieties?.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Product Type
            </label>
            <div class="uk-form-controls">
              <input
                name="product_type"
                :value="detailPlantation.product_type?.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <div class="uk-form-label uk-margin-auto-vertical">
              Tree
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                name="tree"
                :value="detailPlantation?.tree !=0 ? formatCurrency(detailPlantation.tree) : '-' || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
              <label class="label-custom">Tree</label>
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Longitude
            </label>
            <div class="uk-form-controls">
              <input
                name="longitude"
                :value="detailPlantation.longitude || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Address
            </label>
            <div class="uk-form-controls">
              <input
                name="address"
                :value="detailPlantation.address || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Status
            </label>
            <div v-if="detailPlantation.status === 1">
              <label-status
                status="active"
                :label="`${status.ACTIVE}`"
                table="false"
              />
            </div>
            <div v-else-if="detailPlantation.status === 0">
              <label-status
                status="inactive"
                :label="`${status.INACTIVE}`"
                table="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
                
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button red uk-margin-right"
        :class="isCanAccessUser('delete', 'PLANTATION') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('delete', 'PLANTATION')"
        @click="showDeleteConfirmModal"
      >
        <img
          :src="`${images}/icon/ic_delete_red.svg`"
          alt="delete"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Delete
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('edit', 'PLANTATION') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('edit', 'PLANTATION')"
        @click.prevent="$router.push({ name: 'PlantationEdit'})"
      >
        <img
          :src="`${images}/icon/ic_edit_white.svg`"
          alt="edit"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATUS } from '@/utils/constant'
import { currencyDigit } from '@/utils/formater'
import LabelStatus from '@/components/globals/LabelStatus'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    LabelStatus
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  data() {
    return{
      status: STATUS
    }
  },
  computed: {
    ...mapGetters({
      detailPlantation: 'plantation/detail_plantation'
    })
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    formatCurrency (val) {
      return currencyDigit(val)
    },
    showDeleteConfirmModal() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id: this.id
      })
    }
  }
}
</script>

<style scoped>
  .uk-form-label {
    font-family: 'interRegular';
    font-weight: 400;
    font-size: 16px !important;
    color: #000000;
    text-transform: none;
  }
  .fields-custom{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .label-custom{
    position: absolute;
    right: 1rem;
  }
</style>
